import { useState, ComponentProps, MouseEvent } from 'react'
import type { ReactNode } from 'react'
import { Dialog } from '../apps/dialog'

type Props = {
  label: string
  style: string
  leftIcon?: string
  rightIcon?: string
  children?: ReactNode
  position?: ComponentProps<typeof Dialog>['dialogPosition']
  title?: string
  showCloseButton?: boolean
  rotate?: boolean
}

export function OpenDialog ({ label, style, leftIcon, rightIcon, children, position, title, showCloseButton, rotate }: Props) {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = (ev: MouseEvent<HTMLDivElement>) => {
    const el = ev.target as HTMLElement
    // NOTE: ダイアログの中身をクリックしても閉じられないようにする対応
    if (!el.closest('.js-dialog') || el.classList.contains('js-close-button')) {
      setIsOpen(false)
    }
  }

  return (
    <>
      <div className={`inline-flex items-center ${rotate ? '-rotate-180' : ''}`}>
        {leftIcon ? <img src={leftIcon} className="mr-1" /> : null}
        <button type="button" onClick={handleOpen} className={style}>
          {label}
        </button>
        {rightIcon ? <img src={rightIcon} className="ml-1" /> : null}
      </div>
      <Dialog open={isOpen} onClose={handleClose} dialogPosition={position || 'start'} showCloseButton={!(showCloseButton === false)} title={title} rotate={rotate}>
        {children}
      </Dialog>
    </>
  )
}
