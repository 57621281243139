import type { ComponentProps } from 'react'
import { StoreSearchForm } from './store_search_form'
import type { SearchParams } from 'libs/http/store'
import { requestSubmit } from 'libs/form'

type StoreSearchFormProps = ComponentProps<typeof StoreSearchForm>
type StoreListSearchFormProps = {
  url: string;
  seed: string | null;
  keyword: string | null;
  communityId: number | null;
  categoryId: number | null;
  otherCategory: boolean | null;
  onlineShoppingAvailable: boolean | null;
  categories: StoreSearchFormProps['categories'];
  communities: StoreSearchFormProps['communities'];
  etabiIconUrl: string;
}

function submitSearch (url: string, seed: string | null, params: SearchParams) {
  requestSubmit(url, 'get', (input) => {
    input('store_search_form[seed]', seed)
    input('store_search_form[keyword]', params.keyword)
    input('store_search_form[community_id]', params.communityId)
    input('store_search_form[category_id]', params.categoryId)
    input('store_search_form[other_category]', params.otherCategory)
    input('store_search_form[online_shopping_available]', params.onlineShoppingAvailable)
    input('store_search_form[furusato_money_available]', params.furusatoMoneyAvailable)
    input('store_search_form[card_usable]', params.cardUsable)
  })
}

export function StoreListSearchForm (props: StoreListSearchFormProps) {
  function handleSubmit (params: SearchParams) {
    submitSearch(props.url, props.seed, params)
  }

  return (
    <StoreSearchForm
      initialValues={props}
      onSubmit={handleSubmit}
      categories={props.categories}
      communities={props.communities}
      keepDetailOpened={true}
      supportOnlineShoppingAvailable={true}
      etabiIconUrl={props.etabiIconUrl}
    />
  )
}
