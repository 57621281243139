// TODO: headlessui の Dialog で置き換える
// https://tailwindui.com/components/application-ui/overlays/modals#component-6a0b582f00c5ec38bf748d9a75559f04 を参考にしている
import type { ReactNode, MouseEvent } from 'react'
import { Transition } from '@headlessui/react'

type Props = {
  open: boolean
  onClose: (ev: MouseEvent<HTMLDivElement>) => void
  dialogPosition: 'start' | 'end' | 'center';
  children: ReactNode
  showCloseButton?: boolean
  title?: string
  rotate?: boolean
}

function dialogPositionToFlexAlignItems (dialogPosition: Props['dialogPosition']): string {
  switch (dialogPosition) {
    case 'start':
      return 'items-start'
    case 'end':
      return 'items-end'
    case 'center':
      return 'items-center'
  }
}

export function Dialog ({ open, onClose, children, showCloseButton, dialogPosition, title, rotate }: Props) {
  const showHeader = title || showCloseButton
  return (
    <div className={`fixed z-50 inset-0 overflow-y-auto ${open ? '' : 'hidden'}`}>
      <div className={`flex ${dialogPositionToFlexAlignItems(dialogPosition)} justify-center min-h-screen pt-4 px-4 pb-20 text-center`}>
        <Transition
          show={open}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true" onClick={onClose}>
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
        </Transition>

        <Transition
          show={open}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4"
          enterTo="opacity-100 translate-y-0"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-4"
          onClick={onClose}
        >
          <div className={`js-dialog inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transition-all ${rotate ? '-rotate-180' : ''}`} role="dialog" aria-modal="true" aria-labelledby="modal-headline">
            { showHeader && <Header title={title} showCloseButton={showCloseButton} onClose={onClose} /> }
            {children}
          </div>
        </Transition>
      </div>
    </div>
  )
}

type HeaderProps = Pick<Props, 'title' | 'showCloseButton' | 'onClose'>
function Header ({ title, showCloseButton, onClose }: HeaderProps) {
  return (
    <div className="flex items-baseline justify-between">
      <div className="text-gray-500 text-sm">{ title }</div>
      { showCloseButton && (<div className="js-close-button fas fa-times cursor-pointer mb-3" onClick={onClose}></div>) }
    </div>
  )
}
